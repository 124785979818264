import { faPlus, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { startCase } from "lodash";
import React, { useEffect, useRef } from "react";

const InputLayout = ({ petitionerData, setPetitionerData, isEditable }) => {
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    if (Array.isArray(petitionerData[name])) {
      const updatedArray = [...petitionerData[name]];
      updatedArray[index] = value;
      setPetitionerData((prev) => ({ ...prev, [name]: updatedArray }));
    } else {
      setPetitionerData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleAddField = (e, fieldName) => {
    e.preventDefault();
    setPetitionerData((prevData) => ({
      ...prevData,
      [fieldName]: [...prevData[fieldName], ""],
    }));
  };

  const handleRemoveField = (index, e, fieldName) => {
    e.preventDefault();
    setPetitionerData((prevData) => {
      const newData = prevData[fieldName].filter((_, idx) => idx !== index);
      return { ...prevData, [fieldName]: newData };
    });
  };

  const textareaRef = useRef(null);

  // Adjust height based on content
  const adjustHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto"; // Reset height
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Set to scroll height
    }
  };

  useEffect(() => {
    adjustHeight(); // Adjust height on mount
  }, [petitionerData.detailed_summary]); // Adjust height when value changes

  return (
    <div className="flex flex-wrap -mx-3 mb-6">
      {Object.entries(petitionerData).map(([key, value]) => (
        <div
          key={key}
          className={`w-full ${
            key === "detailed_summary" ? "sm:w-full" : "sm:w-1/2"
          }  px-3 mb-6 md:mb-0`}
        >
          <label className="block startcase tracking-wide text-[#40566D] text-xs mb-2 font-semibold">
            {startCase(key)}
          </label>

          {Array.isArray(value) ? (
            <>
              {value.map((item, index) => (
                <div className="flex items-center" key={index}>
                  {key === "explanation" ||
                  key === "ground" ||
                  key === "source" ||
                  key === "description" ||
                  key === "question" ||
                  key === "request" ? (
                    <textarea
                      className="appearance-none block w-full text-sm disabled:bg-[#6C849D52] text-[#40566D] border border-white-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none bg-white"
                      type="text"
                      name={key}
                      value={item}
                      onChange={(e) => handleInputChange(e, index)}
                      disabled={!isEditable}
                    />
                  ) : (
                    <input
                      className="appearance-none block w-full text-sm disabled:bg-[#6C849D52] text-[#40566D] border border-white-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none bg-white"
                      type="text"
                      name={key}
                      value={item}
                      onChange={(e) => handleInputChange(e, index)}
                      disabled={!isEditable}
                      onInput={adjustHeight}
                    />
                  )}
                  {isEditable && (
                    <button
                      className="bg-[#D92D20] mx-2 mb-3 px-4 py-3.5 text-white rounded-md text-xs"
                      onClick={(e) => handleRemoveField(index, e, key)}
                    >
                      <FontAwesomeIcon icon={faTrashCan} />
                    </button>
                  )}
                </div>
              ))}
              {isEditable ? (
                <div className="flex justify-center items-center">
                  <button
                    className="text-[#2950DA] px-2.5 py-2 border-[#2950DA] border rounded-md font-semibold mt-2 text-xs"
                    onClick={(e) => handleAddField(e, key)}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                    <span className="ms-1"> Add</span>
                  </button>
                </div>
              ) : null}
            </>
          ) : (
            <div className="flex items-center">
              {key === "detailed_summary" ? (
                <textarea
                  ref={textareaRef}
                  className="appearance-none block w-full disabled:bg-[#6C849D52] text-[#40566D] text-sm border border-white-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none bg-white resize-none"
                  value={value}
                  onChange={handleInputChange}
                  disabled={!isEditable}
                  onInput={adjustHeight} // Adjust height on input
                />
              ) : (
                <input
                  className="appearance-none block w-full disabled:bg-[#6C849D52] text-[#40566D] text-sm border border-white-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none bg-white"
                  type="text"
                  name={key}
                  defaultValue={value}
                  onChange={handleInputChange}
                  disabled={!isEditable}
                />
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default InputLayout;
