import React, { useContext, useEffect, useState } from "react";
import Navbuttons from "./Navbuttons";
import CaseDetail from "./CaseDetail";
import Petitioner from "./Petitioner";
import Respondent from "./Respondent";
import EarlierCourts from "./EarlierCourts";
import QuestionsOfLaw from "./QuestionsOfLaw";
import ProvisionsOfLaw from "./ProvisionOfLaw";
import Prayers from "./Prayers";
import GroundsOfAppeal from "./GroundOfAppeal";
import ViewDocument from "./ViewDocument";
import { UserContext } from "../Routing";
import useEfileHistory from "./useEfileHistory";
// import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import useUpdateEfiling from "./useUpdateEfiling";
import ConfirmModal from "./ConfirmModal";
import { ToastContainer } from "react-toastify";
import DetailedSummary from "./DetailedSummary";
import { Oval } from "react-loader-spinner";
import CodeCategories from "./CodeCategories";
const { REACT_APP_BASE_URL } = process.env;

// const TAB_COMPONENT_MAPPING = {
//   Case_Detail: CaseDetail,
//   Petitioner: Petitioner,
//   Respondent: Respondent,
//   Earlier_Courts: EarlierCourts,
//   View_Document: ViewDocument,
// };

const EfilingHistory = () => {
  const userState = useContext(UserContext);
  const { userDetails = {} } = userState || {};
  const { userData = {} } = userDetails;
  const { authRole } = userData;

  const urlParams = new URLSearchParams(window.location.search);
  const encodedData = urlParams.get("data");
  const decodedData = JSON.parse(decodeURIComponent(encodedData));
  const { id } = decodedData || {};
  const [isEditable, setIsEditable] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [activeTab, setActiveTab] = useState("document_details");
  // const Component = TAB_COMPONENT_MAPPING[activeTab] || null;

  const { updatedData = {}, setUpdatedData = () => {} } = useEfileHistory({
    id,
  });

  const handleEdit = (e) => {
    e.preventDefault();
    setIsEditable(true);
  };
  const handleCancel = (e) => {
    e.preventDefault();
    setIsEditable(false);
  };

  // const navigate = useNavigate();

  // if (!Component) {
  //   return null;
  // }

  const [caseDetailsData, setCaseDetailsData] = useState({
    CauseTitlePetitioner: updatedData?.CauseTitlePetitioner || "",
    NumberOfPetitioners: updatedData?.NumberOfPetitioners || "",
    NumberOfRespondents: updatedData?.NumberOfRespondents || "",
    CaseType: updatedData?.CaseType || "",
    CourtType: updatedData?.CourtType || "",
    StateName: updatedData?.StateName || "",
    BenchName: updatedData?.BenchName || "",
    MainCategory: updatedData?.MainCategory || "",
    CauseTitleRespondent: updatedData?.CauseTitleRespondent || [""],
    SpecialCategories: updatedData?.SpecialCategories || [""],
    SubCategories: updatedData?.SubCategories || [""],
  });

  useEffect(() => {
    setCaseDetailsData({
      CauseTitlePetitioner: updatedData?.CauseTitlePetitioner || "",
      NumberOfPetitioners: updatedData?.NumberOfPetitioners || "",
      NumberOfRespondents: updatedData?.NumberOfRespondents || "",
      CaseType: updatedData?.CaseType || "",
      CourtType: updatedData?.CourtType || "",
      StateName: updatedData?.StateName || "",
      BenchName: updatedData?.BenchName || "",
      MainCategory: updatedData?.MainCategory || "",
      CauseTitleRespondent: updatedData?.CauseTitleRespondent || [""],
      SpecialCategories: updatedData?.SpecialCategories || [""],
      SubCategories: updatedData?.SubCategories || [""],
    });
  }, [updatedData]);

  const [petitionerDetails, setPetitionerDetails] = useState({
    MainPetitionerName: updatedData?.MainPetitionerName,
    PetitionerType: updatedData?.PetitionerType,
    PetitionerAge: updatedData?.PetitionerAge,
    PetitionerParent: updatedData?.PetitionerParent,
    PetitionerSpouse: updatedData?.PetitionerSpouse,
    PetitionerMobile: updatedData?.PetitionerMobile,
    PetitionerEMail: updatedData?.PetitionerEMail,
    PetitionerCity: updatedData?.PetitionerCity,
    PetitionerDistrict: updatedData?.PetitionerDistrict,
    PetitionerState: updatedData?.PetitionerState,
    PetitionerPincode: updatedData?.PetitionerPincode,
    PetitionerAddress: updatedData?.PetitionerAddress,
  });

  const [respondentDetails, setRespondentDetails] = useState({
    MainRespondentName: updatedData?.MainRespondentName,
    RespondentType: updatedData?.RespondentType,
    RespondentAge: updatedData?.RespondentAge,
    RespondentParent: updatedData?.RespondentParent,
    RespondentSpouse: updatedData?.RespondentSpouse,
    RespondentMobile: updatedData?.RespondentMobile,
    RespondentEMail: updatedData?.RespondentEMail,
    RespondentCity: updatedData?.RespondentCity,
    RespondentDistrict: updatedData?.RespondentDistrict,
    RespondentState: updatedData?.RespondentState,
    RespondentPincode: updatedData?.RespondentPincode,
    RespondentAddress: updatedData?.RespondentAddress,
  });

  const [earlierCourtDetails, setEarlierCourtDetails] = useState({
    AdvocateName: updatedData?.AdvocateName,
    AdvocateRegistrationNumber: updatedData?.AdvocateRegistrationNumber,
    AdvocateMobile: updatedData?.AdvocateMobile,
    AdvocateEMail: updatedData?.AdvocateEMail,
    ImpugnedFinalOrderDate: updatedData?.ImpugnedFinalOrderDate,
    ImpugnedInterimOrderDate: updatedData?.ImpugnedInterimOrderDate,
    ImpugnedOrderCourt: updatedData?.ImpugnedOrderCourt,
    OrderChallenged: updatedData?.OrderChallenged,
    ImpugnedOrderJudges: updatedData?.ImpugnedOrderJudges,
  });

  const [provisionsOfLawDetails, setProvisionsOfLawDetails] = useState({
    provisions_of_law: updatedData?.provisions_of_law,
  });
  const [groundsOfAppealDetails, setGroundsOfAppealDetails] = useState({
    grounds_of_appeal: updatedData?.grounds_of_appeal,
  });
  const [prayersDetails, setPrayersDetails] = useState({
    prayers: updatedData?.prayers,
  });
  const [questionsOfLawDetails, setQuestionsOfLawDetails] = useState({
    questions_of_law: updatedData?.questions_of_law,
  });

  const [detailedSummary, setDetailedSummary] = useState({
    detailed_summary: updatedData?.detailed_summary || "",
  });

  useEffect(() => {
    setDetailedSummary({ detailed_summary: updatedData.detailed_summary });
  }, [updatedData]);

  useEffect(() => {
    var sources = [""];
    if (Array.isArray(updatedData?.provisions_of_law)) {
      sources = updatedData?.provisions_of_law.map((provision) => {
        return provision.source + ", " + provision.reference;
      });
    }
    // var references=[""];
    // if (Array.isArray(updatedData?.provisions_of_law)) {
    //   references = updatedData?.provisions_of_law.map(provision => provision.reference);
    // }
    var descriptions = [""];
    if (Array.isArray(updatedData?.provisions_of_law)) {
      descriptions = updatedData?.provisions_of_law.map(
        (provision) => provision.description
      );
    }
    setProvisionsOfLawDetails({
      source: sources,
      // reference: references,
      description: descriptions,
    });
  }, [updatedData]);

  useEffect(() => {
    var grounds = [""];
    if (Array.isArray(updatedData?.grounds_of_appeal)) {
      grounds = updatedData?.grounds_of_appeal.map(
        (provision) => provision.ground
      );
    }
    var explanations = [""];
    if (Array.isArray(updatedData?.grounds_of_appeal)) {
      explanations = updatedData?.grounds_of_appeal.map(
        (provision) => provision.explanation
      );
    }
    setGroundsOfAppealDetails({
      ground: grounds,
      explanation: explanations,
    });
  }, [updatedData]);

  useEffect(() => {
    var requests = [""];
    if (Array.isArray(updatedData?.prayers)) {
      requests = updatedData?.prayers.map((provision) => provision.request);
    }
    var descriptions = [""];
    if (Array.isArray(updatedData?.prayers)) {
      descriptions = updatedData?.prayers.map(
        (provision) => provision.description
      );
    }
    setPrayersDetails({
      request: requests,
      description: descriptions,
    });
  }, [updatedData]);

  useEffect(() => {
    var questions = [""];
    if (Array.isArray(updatedData?.questions_of_law)) {
      questions = updatedData?.questions_of_law.map(
        (provision) => provision.question
      );
    }
    var explanations = [""];
    if (Array.isArray(updatedData?.questions_of_law)) {
      explanations = updatedData?.questions_of_law.map(
        (provision) => provision.explanation
      );
    }

    setQuestionsOfLawDetails({
      question: questions,
      explanation: explanations,
    });
  }, [updatedData]);

  useEffect(() => {
    setEarlierCourtDetails({
      AdvocateName: updatedData?.AdvocateName,
      AdvocateRegistrationNumber: updatedData?.AdvocateRegistrationNumber,
      AdvocateMobile: updatedData?.AdvocateMobile,
      AdvocateEMail: updatedData?.AdvocateEMail,
      ImpugnedFinalOrderDate: updatedData?.ImpugnedFinalOrderDate,
      ImpugnedInterimOrderDate: updatedData?.ImpugnedInterimOrderDate,
      ImpugnedOrderCourt: updatedData?.ImpugnedOrderCourt,
      OrderChallenged: updatedData?.OrderChallenged,
      ImpugnedOrderJudges: updatedData?.ImpugnedOrderJudges,
    });
  }, [updatedData]);

  useEffect(() => {
    setRespondentDetails({
      MainRespondentName: updatedData?.MainRespondentName,
      RespondentType: updatedData?.RespondentType,
      RespondentAge: updatedData?.RespondentAge,
      RespondentParent: updatedData?.RespondentParent,
      RespondentSpouse: updatedData?.RespondentSpouse,
      RespondentMobile: updatedData?.RespondentMobile,
      RespondentEMail: updatedData?.RespondentEMail,
      RespondentCity: updatedData?.RespondentCity,
      RespondentDistrict: updatedData?.RespondentDistrict,
      RespondentState: updatedData?.RespondentState,
      RespondentPincode: updatedData?.RespondentPincode,
      RespondentAddress: updatedData?.RespondentAddress,
    });
  }, [updatedData]);

  useEffect(() => {
    setPetitionerDetails({
      MainPetitionerName: updatedData?.MainPetitionerName,
      PetitionerType: updatedData?.PetitionerType,
      PetitionerAge: updatedData?.PetitionerAge,
      PetitionerParent: updatedData?.PetitionerParent,
      PetitionerSpouse: updatedData?.PetitionerSpouse,
      PetitionerMobile: updatedData?.PetitionerMobile,
      PetitionerEMail: updatedData?.PetitionerEMail,
      PetitionerCity: updatedData?.PetitionerCity,
      PetitionerDistrict: updatedData?.PetitionerDistrict,
      PetitionerState: updatedData?.PetitionerState,
      PetitionerPincode: updatedData?.PetitionerPincode,
      PetitionerAddress: updatedData?.PetitionerAddress,
    });
  }, [updatedData]);

  const handleSave = (e) => {
    e.preventDefault();
    setUpdatedData((prev) => ({
      ...prev,
      CauseTitlePetitioner: caseDetailsData?.CauseTitlePetitioner,
      CauseTitleRespondent: caseDetailsData?.CauseTitleRespondent,
      NumberOfPetitioners: caseDetailsData?.NumberOfPetitioners,
      NumberOfRespondents: caseDetailsData?.NumberOfRespondents,
      CaseType: caseDetailsData?.CaseType,
      CourtType: caseDetailsData?.CourtType,
      MainCategory: caseDetailsData?.MainCategory,
      SpecialCategories: caseDetailsData?.SpecialCategories,
      SubCategories: caseDetailsData?.SubCategories,
      BenchName: caseDetailsData?.BenchName,
      StateName: caseDetailsData?.StateName,
      // RespondentAddress: caseDetailsData?.RespondentAddress,
      MainPetitionerName: petitionerDetails?.MainPetitionerName,
      PetitionerType: petitionerDetails?.PetitionerType,
      PetitionerAge: petitionerDetails?.PetitionerAge,
      PetitionerParent: petitionerDetails?.PetitionerParent,
      PetitionerSpouse: petitionerDetails?.PetitionerSpouse,
      PetitionerMobile: petitionerDetails?.PetitionerMobile,
      PetitionerEMail: petitionerDetails?.PetitionerEMail,
      PetitionerCity: petitionerDetails?.PetitionerCity,
      PetitionerDistrict: petitionerDetails?.PetitionerDistrict,
      PetitionerState: petitionerDetails?.PetitionerState,
      PetitionerPincode: petitionerDetails?.PetitionerPincode,
      PetitionerAddress: petitionerDetails?.PetitionerAddress,
      MainRespondentName: respondentDetails?.MainRespondentName,
      RespondentType: respondentDetails?.RespondentType,
      RespondentAge: respondentDetails?.RespondentAge,
      RespondentParent: respondentDetails?.RespondentParent,
      RespondentSpouse: respondentDetails?.RespondentSpouse,
      RespondentMobile: respondentDetails?.RespondentMobile,
      RespondentEMail: respondentDetails?.RespondentEMail,
      RespondentCity: respondentDetails?.RespondentCity,
      RespondentDistrict: respondentDetails?.RespondentDistrict,
      RespondentState: respondentDetails?.RespondentState,
      RespondentPincode: respondentDetails?.RespondentPincode,
      RespondentAddress: respondentDetails?.RespondentAddress,
      AdvocateName: earlierCourtDetails?.AdvocateName,
      AdvocateRegistrationNumber:
        earlierCourtDetails?.AdvocateRegistrationNumber,
      AdvocateMobile: earlierCourtDetails?.AdvocateMobile,
      AdvocateEMail: earlierCourtDetails?.AdvocateEMail,
      ImpugnedFinalOrderDate: earlierCourtDetails?.ImpugnedFinalOrderDate,
      ImpugnedInterimOrderDate: earlierCourtDetails?.ImpugnedInterimOrderDate,
      ImpugnedOrderCourt: earlierCourtDetails?.ImpugnedOrderCourt,
      ImpugnedOrderJudges: earlierCourtDetails?.ImpugnedOrderJudges,
      OrderChallenged: earlierCourtDetails?.OrderChallenged,
      // RespondentState: earlierCourtDetails?.RespondentState,
      // RespondentPincode: earlierCourtDetails?.RespondentPincode,
      // RespondentAddress: earlierCourtDetails?.RespondentAddress,
    }));
    setShowUpdateModal(true);
    setIsEditable(false);
  };

  const { handleEfilingUpdate } = useUpdateEfiling({ updatedData });

  const handleFileNameClick = async (id) => {
    const encodedData = { id: id };
    const navigateURL = `${REACT_APP_BASE_URL}/SelectedFileAnalysis?data=${JSON.stringify(
      encodedData
    )}`;
    window.open(navigateURL, "_self");
  };

  return (
    <div className="py-2 mx-4">
      <ToastContainer />
      <div className="flex justify-between">
        {/* <button onClick={() => navigate("/E-Filing")}> */}
        <button onClick={() => handleFileNameClick(id)}>
          <FontAwesomeIcon icon={faArrowLeftLong} />
        </button>
        <h1 className="text-[#40566D] text-xl font-semibold">
          E-Filling History
        </h1>
      </div>
      <hr className="sm:-mx-4 mt-2"></hr>
      <Navbuttons activeTab={activeTab} setActiveTab={setActiveTab} />

      {updatedData.status === "Completed" ? (
        <>
          {authRole !== "User" && activeTab === "document_details" ? (
            <div className="flex justify-end">
              {isEditable ? (
                <button
                  onClick={handleCancel}
                  className="text-[#2950DA] px-4 py-2 border-[#2950DA] border shadow rounded-md font-semibold text-xs"
                >
                  Cancel
                </button>
              ) : (
                <button
                  onClick={handleEdit}
                  className="bg-[#2950DA] text-white px-6 py-2 border-[#2950DA] border shadow rounded-md font-semibold text-xs"
                >
                  Edit
                </button>
              )}
            </div>
          ) : null}
          {activeTab === "document_details" ? (
            <>
              <CaseDetail
                caseDetailsData={caseDetailsData}
                setCaseDetailsData={setCaseDetailsData}
                isEditable={isEditable}
                setIsEditable={setIsEditable}
              />
              <Petitioner
                petitionerDetails={petitionerDetails}
                setPetitionerDetails={setPetitionerDetails}
                isEditable={isEditable}
                setIsEditable={setIsEditable}
              />
              <Respondent
                respondentDetails={respondentDetails}
                setRespondentDetails={setRespondentDetails}
                isEditable={isEditable}
                setIsEditable={setIsEditable}
              />
              <EarlierCourts
                earlierCourtDetails={earlierCourtDetails}
                setEarlierCourtDetails={setEarlierCourtDetails}
                isEditable={isEditable}
              />
              <ProvisionsOfLaw
                provisionsOfLawDetails={provisionsOfLawDetails}
                setProvisionsOfLawDetails={setProvisionsOfLawDetails}
                isEditable={isEditable}
              />
              <GroundsOfAppeal
                groundsOfAppealDetails={groundsOfAppealDetails}
                setGroundsOfAppealDetails={setGroundsOfAppealDetails}
                isEditable={isEditable}
              />

              <QuestionsOfLaw
                questionsOfLawDetails={questionsOfLawDetails}
                setQuestionsOfLawDetails={setQuestionsOfLawDetails}
                isEditable={isEditable}
              />
              <Prayers
                prayersDetails={prayersDetails}
                setPrayersDetails={setPrayersDetails}
                isEditable={isEditable}
              />
              <CodeCategories
                updatedData={updatedData}
                isEditable={isEditable}
              />

              <DetailedSummary
                isEditable={isEditable}
                detailedSummary={detailedSummary}
                setDetailedSummary={setDetailedSummary}
              />
            </>
          ) : null}
          {activeTab === "View_Document" ? <ViewDocument id={id} /> : null}
          {activeTab === "document_details" ? (
            <div className="flex justify-end py-2">
              <button
                onClick={(e) => handleSave(e)}
                className="bg-[#2950DA] px-6 py-2 text-white border-[#2950DA] border shadow-xl rounded-md font-semibold text-xs"
              >
                Save
              </button>
            </div>
          ) : null}
        </>
      ) : (
        <div className="mt-12 mx-auto flex justify-center">
          <Oval
            height={120}
            width={120}
            color="Indigo"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="Indigo"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}
      {showUpdateModal ? (
        <ConfirmModal
          showModal={showUpdateModal}
          setShowModal={setShowUpdateModal}
          handleEfilingUpdate={handleEfilingUpdate}
          id={id}
        />
      ) : null}
    </div>
  );
};

export default EfilingHistory;
