import React from "react";
function Navbuttons({ activeTab, setActiveTab }) {
  const handleActiveTab = (value) => {
    setActiveTab(value);
  };
  return (
    <div className="flex w-full items-center my-4">
      {/* <div className="text-[#4D64DC] text-2xl my-4 text-center font-semibold ">
        E-Filing history
      </div>
      <div className="w-full flex px-2 mb-4">
        <button
          className={
            activeTab === "Case_Detail"
              ? "text-white shadow-md p-2 w-full  bg-[#4D64DC]"
              : "bg-white shadow-md hover:bg-[#7384db] hover:text-white p-2 w-full"
          }
          onClick={() => {
            handleActiveTab("Case_Detail");
          }}
        >
          Case Detail
        </button>
        <button
          className={
            activeTab === "Petitioner"
              ? "text-white shadow-md p-2 w-full  bg-[#4D64DC]"
              : "bg-white shadow-md hover:bg-[#7384db] hover:text-white p-2 w-full"
          }
          onClick={() => {
            handleActiveTab("Petitioner");
          }}
        >
          Petitioner
        </button>
        <button
          className={
            activeTab === "Respondent"
              ? "text-white shadow-md p-2 w-full bg-[#4D64DC]"
              : "bg-white shadow-md hover:bg-[#7384db] hover:text-white p-2 w-full"
          }
          onClick={() => {
            handleActiveTab("Respondent");
          }}
        >
          Respondent
        </button>
        <button
          className={
            activeTab === "Earlier_Courts"
              ? "text-white shadow-md p-2 w-full  bg-[#4D64DC]"
              : "bg-white shadow-md hover:bg-[#7384db] hover:text-white p-2 w-full"
          }
          onClick={() => {
            handleActiveTab("Earlier_Courts");
          }}
        >
          Earlier Courts
        </button>

        <button
          onClick={() => {
            handleActiveTab("View_Document");
          }}
          className={
            activeTab === "View_Document"
              ? "text-white shadow-md p-2 w-full  bg-[#4D64DC]"
              : "bg-white shadow-md hover:bg-[#7384db] hover:text-white p-2 w-full"
          }
        >
          View Document
        </button>
      </div> */}
      <div className="w-1/2 flex justify-center items-center">
        <button
          onClick={() => setActiveTab("document_details")}
          className={
            activeTab === "document_details"
              ? "text-[#305EFF] border-b py-1.5 w-full border-[#305EFF]"
              : "text-[#768EA7] border-b w-full py-1.5 border-gray-200"
          }
        >
          Document Details
        </button>
      </div>
      <div className="w-1/2 flex justify-center items-center">
        <button
          onClick={() => {
            handleActiveTab("View_Document");
          }}
          className={
            activeTab === "View_Document"
              ? "text-[#305EFF] border-b py-1.5 w-full border-[#305EFF]"
              : "text-[#768EA7] border-b w-full py-1.5 border-gray-200"
          }
        >
          View Document
        </button>
      </div>
    </div>
  );
}

export default Navbuttons;
